import styled from 'styled-components'
import {
  md,
  sm,
  themeColor,
  lg,
  white,
  sh,
  primaryColor,
  xl,
  metaRegFont,
} from 'config/variables'
import { images } from 'config/images'

export const RangeServiceListStyle = styled.section`
  margin-bottom: 120px;
  ${xl(`
    margin-bottom: 100px;
  `)}
  ${lg(`
    margin-bottom: 90px;
  `)}
   ${md(`
    margin-bottom: 40px;
  `)}
  .inner-wrapper {
    background-color: #fbfcff;
    padding: 70px 15px;
    ${lg(`
    padding: 60px 15px;
  `)}
   ${md(`
    padding: 40px 15px;
  `)}
  }
  .heading-wrapper {
    max-width:750px;
  }
  .heading-para {
    max-width: 990px;
    margin: 0 auto;
    text-align: center;
    ${sh(`
      max-width: 615px;
    `)}
    ${md(`
      display: none;
    `)}
    p {
      font-size: 24px;
      letter-spacing: -0.15px;
      line-height: 33px;
      margin-bottom: 34px;
      ${sh(`
        font-size: 21px;
        line-height: 30px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 24px;
      `)}
      ${sm(`
        font-size: 18px;
        line-height: 28px;
     `)}
    }
  }
  .heading-details {
    display: flex;
    margin: 48px auto 0;
    align-items: center;
    max-width: 750px; 
    ${lg(`
        margin: 30px auto 0; 
      `)}
    ${md(`
        margin: 20px auto 0;
    `)}
    ul {
        margin:0px;
        padding:0px;
        li {
            font-size:20px;
            font-family: ${metaRegFont};
            font-weight: 400;
            line-height:38px;
            letter-spacing: -0.15px;
            padding-left:34px;
            color: ${primaryColor};
            margin-bottom:12px;
            list-style:none;
            background:url(${images.listTickIcon}) no-repeat left top 10px;
            &:last-child {
              margin-bottom: 0;
            } 
            ${lg(`
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 20px;
                background:url(${images.listTickIcon}) no-repeat left top 4px;
            `)}
            ${sm(`
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 15px;
            `)}
        }
      }
  } 
`
