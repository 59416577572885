import { lg, md, sm, xl } from 'config/variables'
import styled from 'styled-components'

export const AWSPartnerCardStyle = styled.div`
    margin-bottom: 120px;
    background-image: linear-gradient(to bottom, rgba(242, 220, 204, 0), #f8f9ff);
    padding-bottom: 98px;
    ${xl(`
        margin-bottom: 90px;
    `)} 
    ${lg(`
        margin-bottom: 60px;
        padding-bottom: 60px;
    `)} 
    ${md(`
        margin-bottom: 40px;
    `)} 
    ${sm(`
        padding-bottom: 40px;
    `)}
    .card-row {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap:30px;
        margin-top:50px;
        ${md(`
            grid-template-columns: 1fr;
        `)}
        .card-item {
            .aws-card {
                border:1px solid #C4C9F9;
                display: block;
                box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.2);
            }
        }
    }
`
