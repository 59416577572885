import React from 'react'
import { AWSPartnerCardStyle } from './AWSPartnerCard.styles'
import { isWindowDefined } from 'config/utils'
import Image from 'components/ImgOptimized'

function AWSPartnerCard(props) {
  const { awardsRecognitionData } = props
  return (
    <AWSPartnerCardStyle>
      <div className="container">
        <div className="heading-wrapper">
          <h2>{awardsRecognitionData.heading}</h2>
        </div>
        <div className="card-row">
          {awardsRecognitionData.AwsCardData.map((data, i) => {
            return (
              <div key={i} className="card-item">
                <a
                  className="aws-card"
                  target={data.openNewTab ? '_blank' : ''}
                  href={`${isWindowDefined ? window.location.origin : ''}${
                    data.cardLink
                  }`}
                >
                  <Image
                    fluid={props.ImageThumb[i]}
                    lazyload={{
                      enabled: true,
                      once: true,
                      offset: 1000,
                      heigth: 210,
                    }}
                  />
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </AWSPartnerCardStyle>
  )
}

export default AWSPartnerCard
