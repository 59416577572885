import styled from 'styled-components'
import { sm, md, sh, xll, xl, lg } from 'config/variables'

export const GuaranteeSectionStyle = styled.section`
  background-image: linear-gradient(to bottom, rgba(242, 220, 204, 0), #f8f9ff);
  padding-bottom: 98px;
  ${lg(`
    padding-bottom: 60px;
  `)}
  ${sm(`
    padding-bottom: 40px;
  `)}
  .container {
    text-align: center;
    max-width: 886px;
    ${sh(`
      max-width: 770px;
    `)}
    h3 {
      margin-bottom: 30px;
    }
    p {
      font-size: 24px;
      letter-spacing: -0.15px;
      line-height: 34px;
      margin-bottom: 30px;
      ${sh(`
        font-size: 21px;
        line-height: 31px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 28px;
        `)}
      ${sm(`
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
        `)}
      strong {
        font-weight: 500;
      }
      .text-bg {
        position: relative;
        color: #cb5fb9;
        &:after {
          content: '';
          position: absolute;
          height: 15px;
          background: #ffe0f9;
          bottom: 5px;
          left: 0;
          width: 100%;
          z-index: -1;
          ${sm(`
            height: 7px;
            bottom: 3px;
            `)}
        }
      }
    }
    .button {
      margin-top: 22px;
    }
  }
  .img-block {
    margin-top: 50px;      
    ${sh(`
      max-width: 70%;
      margin: 0 auto;
      margin-top: 50px;
    `)}
    ${xll(`
      max-width: 80%;
      margin: 0 auto;
      margin-top: 50px;
    `)}
    ${xl(`
      max-width: 80%;
      margin: 0 auto;
      margin-top: 50px;
    `)}
    ${lg(`
      max-width: 95%;
      margin: 0 auto;
      margin-top: 50px;
    `)}
    ${sm(`
      margin-top: 30px;
    `)}
    img {
      display: block;
      margin: 0 auto;
    }
  }
`
