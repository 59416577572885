import React, { useState } from 'react'
import { RangeServiceListStyle } from './RangeServiceListSec.styles'

const RangeServiceListSec = props => {
  const { rangeServiceListData } = props

  return (
    <RangeServiceListStyle>
      <div className="container">
        <div className="inner-wrapper">
          <div className="heading-wrapper">
            <h2 className="h3">
              {rangeServiceListData.RangeServiceListHeading}
            </h2>
          </div>
          {rangeServiceListData.buildAppPara && (
            <div className="heading-para">
              <p>{rangeServiceListData.buildAppPara}</p>
            </div>
          )}
          {rangeServiceListData.detailsList && (
            <div className="heading-details">
              <ul>
                {rangeServiceListData.detailsList.map((item, i) => {
                  return <li key={i}>{item}</li>
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </RangeServiceListStyle>
  )
}

export default RangeServiceListSec
