import React, { memo } from 'react'
import Button from 'components/Button/Button'
import { GuaranteeSectionStyle } from './GuaranteeSection.styles'
import Image from 'components/ImgOptimized'

const GuaranteeSection = memo(
  ({ gauranteesecdata: { gauranteeSecProps }, data }) => {
    const {
      gauranteeTeam: {
        childImageSharp: { fluid: gauranteeTeam },
      },
    } = data

    return (
      <GuaranteeSectionStyle className="guarantee-section">
        <div className="container">
          <h2 className="h3">{gauranteeSecProps.heading}</h2>
          {gauranteeSecProps.para && <p>{gauranteeSecProps.para}</p>}
          {gauranteeSecProps.buttonName && (
            <Button
              buttonName={gauranteeSecProps.buttonName}
              buttonLink={gauranteeSecProps.buttonURL}
            />
          )}
        </div>
        <div className="img-block">
          <Image
            fluid={gauranteeTeam}
            alt={gauranteeSecProps.gauranteeAlt}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 433,
            }}
          />
        </div>
      </GuaranteeSectionStyle>
    )
  }
)

export default GuaranteeSection
